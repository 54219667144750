<template>
  <div class="wrapper">
    <Header></Header>
    <router-view />
    <el-footer></el-footer>
  </div>
</template>

<script>
import ENUM from "../../../const/enum";
import Header from "../../../components/Header";
export default {
  components: {
    Header,
  },
  methods: {
    handleSelect(key, keyPath) {
      if (location.pathname !== key) this.$router.push(key);
    },
    // CHANGE_LIST_POSTS(){
    //   this.storeVue()
    // }
  },
  created() {
    // this.postJSON(ENUM.POSTS.GET_POSTS, { limit: 2, from: 0 }, r => {
    //   console.log(46, r)
    // })
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 6px;
  background: #f6f7f9;
  box-shadow: inset 0px 0px 0px #f6f7f9, inset 0px 0px 0px 0px #f6f7f9;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #cccccc;
  border: none;
  border-radius: 100px;
  box-shadow: inset 0px 0px 3px #cccccc;
}
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background: #888888;
  box-shadow: inset 0px 0px 3px #888888;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
<style lang="scss">
.wrapper {
  header {
    padding: 0;
    > ul {
      padding: 0 10px;
    }
  }
}
</style>